import './video-media.scss';

class VideoMedia {
    constructor ($element, options) {
        const defaults = {
            videoAttr: 'video'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$videoMedia = $element;
        this.$videoMediaFile = this.$videoMedia.querySelector('[data-videomedia="' + this.settings.videoAttr + '"]');

        this.initialize();
    }

    initialize () {
        if (this.$videoMediaFile !== null) {
            import('../../utilities/js/youtube-player').then(({ YoutubePlayer }) => {
                this.initYoutubePlayer(YoutubePlayer);
            });
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        const $video = this.$videoMediaFile;

        if ($video !== null) {
            const videoPlayer = new YoutubePlayer($video, {
                eventScroller: window.eventScroller,
                autoplay: true,
                onCall: () => {
                    this.$videoMedia.classList.add('show--video');
                },
                onStart: (videoId) => {
                    console.log(videoId);
                },
                onEnd: (videoId) => {
                    console.log(videoId);
                    if (videoPlayer.player.video.isMuted() === false || videoPlayer.mute === 0) {
                        videoPlayer.destroy();
                        this.$videoMedia.classList.remove('show--video');
                    }
                },
                onPause: (videoId, progress) => {
                    console.log(videoId, progress);
                }
            });
        }
    }
}

export { VideoMedia };

/*
window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$videoMedia = $context.querySelectorAll('[data-videomedia="root"]');

        if ($$videoMedia.length > 0) {
            window.onYouTubeIframeAPIReady = () => {
                console.log('iframe api ready, init module');
                for (let i = 0; i < $$videoMedia.length; i++) {
                    const $videoMedia = $$videoMedia[i];

                    const videoMediaAPI = new VideoMedia($videoMedia);
                    $videoMedia.API = videoMediaAPI;
                }
            };
        }
    }
});
*/

window.onYouTubeIframeAPIReady = () => {
    const $$videoMedia = document.querySelectorAll('[data-videomedia="root"]');
    if ($$videoMedia.length > 0) {
        console.log('iframe api ready, init module');
        for (let i = 0; i < $$videoMedia.length; i++) {
            const $videoMedia = $$videoMedia[i];

            const videoMediaAPI = new VideoMedia($videoMedia);
            $videoMedia.API = videoMediaAPI;
        }
    }
};
